import { FeelingType, LanguageType, PlacementType, StatusType, TagType } from '@enums';

export const localeFilter: {
  title: string;
  value: LanguageType;
  isEnabled: boolean;
}[] = [
  {
    title: 'english',
    value: LanguageType.EN,
    isEnabled: false,
  },
  {
    title: 'dutch',
    value: LanguageType.NL,
    isEnabled: false,
  }
];

export const statusFilter: {
  title: string;
  value: StatusType;
  isEnabled: boolean;
  color: 'default' | 'yellow' | 'green' | 'gray' | 'orange';
}[] = [
  {
    title: 'Draft',
    value: StatusType.DRAFT,
    isEnabled: false,
    color: 'orange'
  },
  {
    title: 'Hold',
    value: StatusType.HOLD,
    isEnabled: false,
    color: 'gray'
  },
  {
    title: 'Publish',
    value: StatusType.PUBLISH,
    isEnabled: false,
    color: 'green'
  },
];

export const feelingTypeFilter: {
  title: string;
  value: FeelingType;
  isEnabled: boolean;
  color: 'default' | 'yellow' | 'green' | 'gray' | 'orange' | 'blue' | 'red';
}[] = [
  {
    title: 'Happy - Very Happy',
    value: FeelingType.HAPPY,
    isEnabled: false,
    color: 'green'
  },
  {
    title: 'Neutral',
    value: FeelingType.NEUTRAL,
    isEnabled: false,
    color: 'blue'
  },
  {
    title: 'Sad - Very Sad',
    value: FeelingType.SAD,
    isEnabled: false,
    color: 'red'
  },
];

export const tagFilter: {
  title: string;
  value: TagType;
  isEnabled: boolean;
  color: 'white';
}[] = [
  {
    title: 'Sleep / Slaap',
    value: TagType.SLEEP,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Focus',
    value: TagType.FOCUS,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Emotion / Emotie',
    value: TagType.EMOTION,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Lifestyles / Levensstijl',
    value: TagType.LIFESTYLES,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Self Esteem / Eigenwaard',
    value: TagType.SELF_ESTEEM,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Stimulus Processing / Prikkelverweking',
    value: TagType.STIMULUS_PROCESSING,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Addiction / Verslaving',
    value: TagType.ADDICTION,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Resilience / Weerbaarheid',
    value: TagType.RESILIENCE,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Executive functions / Executieve functies',
    value: TagType.EXECUTIVE_FUNCTIONS,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Psychological Problems / Psychische Problematiek',
    value: TagType.PSYCHOLOGICAL_PROBLEMS,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Stress',
    value: TagType.STRESS,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Emotions and Affection / Emoties en Affectie',
    value: TagType.EMOTIONS_AND_AFFECTION,
    isEnabled: false,
    color: 'white'
  },
];

export const hackPlacementFilter: {
  title: string;
  value: PlacementType;
  isEnabled: boolean;
  color: 'white';
}[] = [
  {
    title: 'All Type',
    value: PlacementType.ALL_TYPE,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Home',
    value: PlacementType.HOME,
    isEnabled: false,
    color: 'white'
  },
  {
    title: 'Library',
    value: PlacementType.LIBRARY,
    isEnabled: false,
    color: 'white'
  },
];

