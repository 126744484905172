export enum TagType {
    SLEEP = 'sleep',
    FOCUS = 'focus',
    EMOTION = 'emotion',
    LIFESTYLES = 'lifestyle',
    SELF_ESTEEM = 'self_esteem',
    STIMULUS_PROCESSING = 'stimulus_processing',
    ADDICTION = 'addiction',
    RESILIENCE = 'resilience',
    EXECUTIVE_FUNCTIONS = 'executive_functions',
    PSYCHOLOGICAL_PROBLEMS = 'psychological_problems',
    STRESS = 'stress',
    EMOTIONS_AND_AFFECTION = 'emotions_and_affection',

    SLAAP = 'slaap',
    EMOTIE = 'emotie',
    LEVENSSTIJL = 'levensstijl',
    EIGENWAARDE = 'eigenwaarde',
    PRIKKELVERWERKING = 'prikkelverwerking',
    VERSLAVING = 'verslaving',
    WEERBAARHEID = 'weerbaarheid',
    EXECUTIEVE_FUNCTIES = 'executieve_functies',
    PSYCHISCHE_PROBLEMATIEK = 'psychische_problematiek',
    EMOTIES_EN_AFFECTIE = 'emoties_en_affectie'
}