import { Injectable, signal } from '@angular/core';
import {
  companyOptions,
  localeOptions,
  localeContentOptions,
  tagOptions,
  feelingOptions,
  placementOptions,
} from '@app/core/models/select-options';
import dayjs from 'dayjs';
import { MilestoneService } from '../contents';
import { Perform } from '@classes';
import { MilestoneInterface } from '@app/feature/milestone/models';
@Injectable({
  providedIn: 'root',
})
export class SelectOptionsService {
  readonly placementOptions = placementOptions;
  readonly localeOptions = localeOptions;
  readonly localeContentOptions = localeContentOptions;
  readonly tagOptions = tagOptions;
  readonly companyOptions = companyOptions;
  readonly feelingOptions = feelingOptions;
  readonly monthOptions = month;

  private months = signal<OptionInterface[]>(month);
  private milestonePerform = new Perform<MilestoneInterface[]>();

  constructor(private milestoneService: MilestoneService) {}

  public getMilestoneMonth(year: number){

    let currentMonth:number;
    if(year === dayjs().year()){
      currentMonth = dayjs().month();
    }else if(year > dayjs().year()){
      currentMonth = 0;
    }
    const remainingMonths = this.months().filter(month => month.value >= currentMonth);
    return remainingMonths;
  }

  public tes(){
    return [];
  }

  public getMilestoneYear(){
    const currentYear = dayjs().year();
    const remainingYears = [
      {
        name: `${currentYear}`,
        value: currentYear,
      },
      {
        name: `${currentYear + 1}`,
        value: currentYear + 1,
      },
    ];

    return remainingYears;
  }
}

interface OptionInterface {
  name: string,
  value: any
}

const month = [
  {
    name: 'January',
    value: 0,
  },
  {
    name: 'February',
    value: 1,
  },
  {
    name: 'March',
    value: 2,
  },
  {
    name: 'April',
    value: 3,
  },
  {
    name: 'May',
    value: 4,
  },
  {
    name: 'June',
    value: 5,
  },
  {
    name: 'July',
    value: 6,
  },
  {
    name: 'August',
    value: 7,
  },
  {
    name: 'September',
    value: 8,
  },
  {
    name: 'October',
    value: 9,
  },
  {
    name: 'November',
    value: 10,
  },
  {
    name: 'December',
    value: 11,
  },
];
