import {
  Component,
  ElementRef,
  inject,
  input,
  Input,
  model,
  signal,
  viewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputBaseClass, Perform } from '@app/core/classes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  saxGalleryOutline,
  saxVideoCircleOutline
} from '@ng-icons/iconsax/outline';
import { InputErrorMessageComponent, MediaViewDialogComponent } from '@components';
import { DialogService, MediaService } from '@services';
import { getDynamicSize, getFileName } from '@functions';
import { MediaInterface } from '@interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { ImageComponent } from '../../media/image/image.component';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [
    FontAwesomeModule,
    ReactiveFormsModule,
    NgIconComponent,
    TranslateModule,
    ImageComponent,
    InputErrorMessageComponent
  ],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  viewProviders: [
    provideIcons({
      saxGalleryOutline,
      saxVideoCircleOutline
    })
  ]
})
export class FileInputComponent extends InputBaseClass {
  public dialogService = inject(DialogService);
  private mediaService = inject(MediaService);

  @Input()
  public maxDimension!: number;

  @Input()
  public maxSizeKb!: number;

  @Input({required:true})
  public validFileType:string[] = [];

  public fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');
  public currentData = model<any>();
  public accessableFileType = input.required<string>();
  public accessableFileTypeText = input.required<string>();
  public fileType = input<'image' | 'video'>('image');

  public mediaPerform = new Perform<string>();
  public dragging = signal<boolean>(false);
  public progress = signal<number>(20);
  public videoPreview = signal<any>(null);
  public file = signal<File | null>(null);
  public imageSrc = signal<any>(null);
  public error = signal<boolean>(false);
  public loading = signal<boolean>(false);


  public ngOnInit() {
    if (this.currentData != undefined) {
      this.control.setValue(this.currentData());
      this.getFileFromAWS(this.currentData()?.url);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragging.set(true);
  }

  public onDragLeave() {
    this.dragging.set(false);
  }

  public onFileSelect() {
    this.fileInput()?.nativeElement.click();
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragging.set(false);
    const droppedFiles = event.dataTransfer?.files;

    if (droppedFiles) {
      const file = droppedFiles[0];
      this.videoPreview.set(URL.createObjectURL(file));
      this.handleFiles(droppedFiles[0]);
    }
  }

  public onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      const file = input.files[0];
      this.videoPreview.set(URL.createObjectURL(file));
      this.handleFiles(input.files[0]);
    }
  }

  private handleFiles(file: File) {
    // check type
    if(this.validFileType){
      if(!this.validFileType.includes(file.type)){
        this.error.set(true);
        return;
      }
    }

    // check size
    if (this.maxSizeKb) {
      if (file.size > this.maxSizeKb * 1024) {
        this.error.set(true);
        this.control.setValue(undefined);
        return;
      }
    }

    this.file.set(file);
    this.loading.set(true);
    this.previewImage(file);
    const uploadInterval = setInterval(() => {
      this.progress.update((currentData) => (currentData += 10));
      if (this.progress() >= 100) {
        clearInterval(uploadInterval);
        this.loading.set(false);
      }
    }, 100);
  }

  public getFileSize(fileSize: number | undefined): string {
    if (fileSize) {
      return getDynamicSize(fileSize);
    }
    return '';
  }

  private previewImage(file: File): void {
    const img = new Image();
    const readerDataURL = new FileReader();
    const readerArrayBuffer = new FileReader();

    if (this.fileType() === 'video') {
      this.imageSrc.set('assets/images/gray.png');
    } else {
      readerDataURL.onload = (event: any) => {
        img.src = event.target.result;
        this.imageSrc.set(event.target.result);
      };

      readerDataURL.readAsDataURL(file);

      img.onload = () => {
        if (img.width > this.maxDimension && img.height > this.maxDimension) {
          this.error.set(true);
          this.file.set(null);
          return;
        }
      };
    }

    readerArrayBuffer.readAsArrayBuffer(file);
    readerArrayBuffer.onload = (event) => {
      const formattedName = file.name
        .toLowerCase()
        .replace(/\s+/g, '_')
        .split('.')[0];
      let fileInputValue: MediaInterface = {
        title: formattedName + '_' + getFileName(file.type),
        value: event.target?.result,
        size: file.size
      };
      this.control.setValue(fileInputValue);
    };

    this.error.set(false);
  }

  public removeFile(): void {
    this.file.set(null);
    this.imageSrc.set(null);
    this.control.setValue(null);
    this.currentData.set(undefined);
  }

  public openMediaViewDetail(data: {
    media: string | ArrayBuffer | null;
    mediaType: 'video' | 'image';
  }) {
    this.dialogService.open(MediaViewDialogComponent, data, true, true);
  }

  private getFileFromAWS(path: string) {
    this.mediaPerform.load(this.mediaService.getMediaUrl(path));
  }
}
